<template>
  <div class="logistics_box">
    <div class="title">
        <img src="../../../../src/assets/image/black.png" alt="" @click="goblack">
        <span>进度详情</span>
    </div>
    <div class="logistics_list">
        <van-steps direction="vertical" :active="0" active-color="#333" inactive-color="#939297">
            <van-step v-for="(item, index) in list" :key="index">
                <h3>{{item.context}}</h3>
                <p>{{item.time}}</p>
            </van-step>
        </van-steps>
    </div>
    <bottomList></bottomList>
  </div>
</template>

<script>
import { ProgressRefund } from '@/api/service' // 进度
export default {
  name: "schedule",

  data() {
    return {
      list: [],
      address: {}
    };
  },

  mounted() {
    if(this.$route.params.orderId) {
        localStorage.setItem('orderId', this.$route.params.orderId)
    } else {
        this.$route.params.orderId = localStorage.getItem('orderId')
    }
    this.getInfo()
  },

  methods: {
    // 返回上一页
    goblack() {
        // 返回上一页
       this.$router.go(-1);
    },
    getInfo() {
      ProgressRefund(this.$route.params.orderId).then(res => {
        this.list = res.data
      })
    }
  },
};
</script>

<style lang="less" scoped>
.logistics_box{
   background: #F6F7FA;
}
.title{
    position: relative;
    text-align: center;
    padding: 14rem 0rem;
    background: #fff;
    img{
        position: absolute;
        left: 14rem;
        width: 17rem;
        height: 17rem;
    }
    span{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 17rem;
        color: #333333;
    }
}
.site{
    width: calc(100vw - 30rem);
    // height: 44rem;
    background: #FFFFFF;
    padding: 15rem;
    .site_text{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left{
          display: flex;
          .imgdinwei{
            width: 20rem;
          }
          .text1{
            font-family: PingFangSC, PingFang SC;
            font-weight: bold;
            font-size: 15rem;
            margin-left: 11rem;
            color: #333333;
          }
        }
        .imgright{
            width: 7rem;
        }
    }
    .time{
      font-family: PingFangSC, PingFang SC;
      font-weight: 100;
      font-size: 14rem;
      color: #000000;
      padding-left: 33rem;
      margin-top: 4rem;
    }
}
.site2{
    border-top: 1rem solid #F6F7FA;
  .imgdinwei{
    width: 23rem !important;
  }
}
.logistics_list{
    height: calc(100vh - 154rem);
    background: #fff;
    padding: 0rem 16rem;
}
/deep/.van-hairline{
    padding: 20rem 0rem;
}
</style>
